function showAccordionB() {
    $('.m-accordion-b__title-wrapper').click(function (e) {
        const target = e.target;
        if (target.closest('a')) {
            return;
        }

        e.preventDefault();

        $(this).parent().toggleClass('is-active');
        // eslint-disable-next-line jquery/no-slide
        $(this).parent().find('.m-accordion-b__content').slideToggle('fast');
    });

    // add listener to closing button
    // triggers click listeners of the '.m-accordion-b__title-wrapper'
    $('.m-accordion-b__close-btn').click(function () {
        console.log($(this).closest('.m-accordion-b__title-wrapper'));
        $(this)
            .parents()
            .closest('.m-accordion-b__repeater-item')
            .find('.m-accordion-b__title-wrapper')
            .click();
    });
}

export default showAccordionB;
