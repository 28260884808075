import Swiper, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

function swiperVideosA() {
    Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);

    const jsHook = 'js-slider-videos-a';
    const videosA = 'o-videos-a-1';

    const bulletTailwind = [
        'w-full',
        'h-0.5',
        'py-6',

        'box-content',
        'bg-clip-content',
        'bg-brand-color-4',

        'opacity-50',
        'cursor-pointer',

        'duration-500',
        'hover:bg-brand-color-1',
    ];
    const bulletActiveTailwind = ['cursor-auto', '!bg-brand-color-8'];

    // Ensuring we don't show buttons if there is just 1 entry outputted
    if ($(`.${jsHook}__slide-item`).length <= 1) {
        $(`.${jsHook}__slider-wrapper`).addClass(
            `${jsHook}__slider-wrapper--disabled`,
        );

        $(`.${jsHook}-btn--prev`).addClass('invisible');
        $(`.${jsHook}-btn--next`).addClass('invisible');

        $(`.${videosA}__slide-item`).addClass('mx-auto');
        $(`.${videosA}__img-wrapper`).removeClass('opacity-0');

        $(`.${videosA}__slider-bottom-wrapper`).addClass('invisible');
    } else {
        $(`.${jsHook}__slider-wrapper`).removeClass(
            `${jsHook}__slider-wrapper--disabled`,
        );

        $(`.${jsHook}-btn--prev`).removeClass(`${jsHook}-btn--prev--disabled`);
        $(`.${jsHook}-btn--next`).removeClass(`${jsHook}-btn--next--disabled`);

        new Swiper(`.${jsHook}`, {
            on: {
                init: function (sw) {
                    const parent = getSliderQuote(sw);

                    $(parent)
                        .find(`.${jsHook}-pagination__total`)
                        .text(
                            $(parent).find(
                                `.${jsHook} .swiper-slide:not(.swiper-slide-duplicate)`,
                            ).length,
                        );

                    setPaginationCurrent(sw, jsHook);
                },
                slideChange: function (sw) {
                    setPaginationCurrent(sw, jsHook);
                },
                afterInit: function (sw) {
                    sw.slides.forEach(function (currentValue) {
                        const card = currentValue.querySelector(
                            '.o-videos-a-1__img-wrapper',
                        );
                        if (card === null) {
                            return;
                        }

                        var index = $(currentValue).data('swiper-slide-index');

                        card.dataset.aos = 'cards-fade-in-up';
                        card.dataset.aosOffset = '0';
                        card.dataset.aosDuration = '1000';
                        card.dataset.aosDelay = `${index}00`;
                    });
                },
            },
            loop: true,
            speed: 600,
            autoplay: {
                delay: 5000,
            },
            noSwipingClass: 'o-videos-a-1__modal-group',
            slidesPerView: 'auto',
            centeredSlides: true,
            spaceBetween: 8,
            breakpoints: {
                768: {
                    spaceBetween: 20,
                },
            },
            navigation: {
                prevEl: `.${jsHook}-btn--prev`,
                nextEl: `.${jsHook}-btn--next`,
            },
            pagination: {
                el: `.${jsHook}-pagination`,
                type: 'bullets',
                clickable: true,
                bulletClass: bulletTailwind.join(' '),
                bulletActiveClass: bulletActiveTailwind.join(' '),
            },
        });
    }
}

function setPaginationCurrent(sw, jsHook) {
    const parent = getSliderQuote(sw);

    $(parent)
        .find(`.${jsHook}-pagination__current`)
        .text(sw.realIndex + 1);
}

function getSliderQuote(sw) {
    return sw.$el[0].closest('[data-module="sliderVideosA"]');
}

export default swiperVideosA;
