import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper';

function hero4() {
    Swiper.use([Autoplay, EffectFade, Pagination]);

    const jsHook = 'js-slider-hero-4';

    const letters = ['S', 'M', 'A', 'R', 'T'];

    new Swiper(`.${jsHook}`, {
        loop: false,
        effect: 'fade',
        allowTouchMove: false,
        fadeEffect: { crossFade: true },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return `<button class="${className}">
                        <svg aria-hidden="true" class="${className}__progress" width="70" height="70" viewBox="0 0 70 70">
                            <path class="${className}__progress-circle" d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z"></path>
                            <path class="${className}__progress-path" d="m35,2.5c17.955803,0 32.5,14.544199 32.5,32.5c0,17.955803 -14.544197,32.5 -32.5,32.5c-17.955803,0 -32.5,-14.544197 -32.5,-32.5c0,-17.955801 14.544197,-32.5 32.5,-32.5z" pathLength="1"></path>
                        </svg>
                        <span class="${className}__letter">${letters[index]}</span>
                    </button>`;
            },
        },
    });

    // Hero Type 4 Slider play-pause logic
    const hero4Slider = document.querySelector('.swiper-container').swiper;
    let isSliderActive = true;

    $('.o-hero-4__slider-control').click(function () {
        if (isSliderActive) {
            hero4Slider.autoplay.stop();
            isSliderActive = false;
        } else {
            hero4Slider.autoplay.start();
            isSliderActive = true;
        }

        $(this).toggleClass('paused');
    });

    // Ensuring we don't show buttons if there is just 1 entry outputted
    /*if ($(`.${jsHook}__slide-item`).length <= 1) {
        $(`.${jsHook}__slider-wrapper`).addClass(
            `${jsHook}__slider-wrapper--disabled`,
        );

        $(`.${jsHook}-btn--prev`).addClass(`${jsHook}-btn--prev--disabled`);
        $(`.${jsHook}-btn--next`).addClass(`${jsHook}-btn--next--disabled`);
    } else {
        $(`.${jsHook}__slider-wrapper`).removeClass(
            `${jsHook}__slider-wrapper--disabled`,
        );

        $(`.${jsHook}-btn--prev`).removeClass(`${jsHook}-btn--prev--disabled`);
        $(`.${jsHook}-btn--next`).removeClass(`${jsHook}-btn--next--disabled`);

        new Swiper(`.${jsHook}`, {
            loop: true,
            effect: 'fade',
            allowTouchMove: false,
            fadeEffect: { crossFade: true },
        });
    }*/
}

export default hero4;
