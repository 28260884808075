import Swiper, { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper';

function sliderCardsB() {
    const jsHook = 'js-slider-cards-b';
    const cardsB = 'o-cards-b-1';
    // 'js-slider-cards-b__btn-answer'
    swiperCardsB(jsHook, cardsB);
    toggleAnswerEvent(jsHook);
}

function toggleAnswerEvent(jsHook) {
    // logic behind the toggling answer card
    const toggleAnswerBtn = $(`.${jsHook}__btn-answer`);

    toggleAnswerBtn.click(function (e) {
        e.preventDefault();
        $(this).parents(`.m-card-text-b`).toggleClass('show-answer');
    });
}

function swiperCardsB(jsHook, cardsB) {
    Swiper.use([Autoplay, Navigation, Pagination, Scrollbar]);

    const bulletTailwind = [
        'w-full',
        'h-0.5',
        'py-6',

        'box-content',
        'bg-clip-content',
        'bg-brand-color-4',

        'opacity-50',
        'cursor-pointer',

        'duration-500',
        'hover:bg-brand-color-1',
    ];
    const bulletActiveTailwind = ['cursor-auto', '!bg-brand-color-8'];

    // Ensuring we don't show buttons if there is just 1 entry outputted
    if ($(`.${jsHook}__slide-item`).length <= 1) {
        $(`.${jsHook}__slider-wrapper`).addClass(
            `${jsHook}__slider-wrapper--disabled`,
        );

        $(`.${jsHook}-btn--prev`).addClass('invisible');
        $(`.${jsHook}-btn--next`).addClass('invisible');

        $(`.${cardsB}__slide-item`).addClass('mx-auto');
        $(`.${cardsB}__slide-item-inner`).removeClass('opacity-0');

        $(`.${cardsB}__slider-bottom-wrapper`).addClass('invisible');
    } else {
        $(`.${jsHook}__slider-wrapper`).removeClass(
            `${jsHook}__slider-wrapper--disabled`,
        );

        $(`.${jsHook}-btn--prev`).removeClass(`${jsHook}-btn--prev--disabled`);
        $(`.${jsHook}-btn--next`).removeClass(`${jsHook}-btn--next--disabled`);

        new Swiper(`.${jsHook}`, {
            on: {
                init: function (sw) {
                    const parent = getSliderQuote(sw);

                    $(parent)
                        .find(`.${jsHook}-pagination__total`)
                        .text(
                            $(parent).find(
                                `.${jsHook} .swiper-slide:not(.swiper-slide-duplicate)`,
                            ).length,
                        );

                    setPaginationCurrent(sw, jsHook);
                },
                slideChange: function (sw) {
                    setPaginationCurrent(sw, jsHook);
                },
                afterInit: function (sw) {
                    sw.slides.forEach(function (currentValue) {
                        const card = currentValue.querySelector(
                            '.o-cards-b-1__slide-item-inner',
                        );
                        if (card === null) {
                            return;
                        }

                        var index = $(currentValue).data('swiper-slide-index');

                        card.dataset.aos = 'cards-fade-in-up';
                        card.dataset.aosOffset = '0';
                        card.dataset.aosDuration = '800';
                        card.dataset.aosDelay = `${index}00`;
                    });
                },
            },
            loop: true,
            speed: 600,
            autoplay: {
                delay: 5000,
            },
            slidesPerView: 'auto',
            centeredSlides: false,
            noSwipingClass: `${jsHook}__btn-answer`,
            spaceBetween: 0,
            navigation: {
                prevEl: `.${jsHook}-btn--prev`,
                nextEl: `.${jsHook}-btn--next`,
            },
            pagination: {
                el: `.${jsHook}-pagination`,
                type: 'bullets',
                clickable: true,
                bulletClass: bulletTailwind.join(' '),
                bulletActiveClass: bulletActiveTailwind.join(' '),
            },
        });
    }
}

function setPaginationCurrent(sw, jsHook) {
    const parent = getSliderQuote(sw);

    $(parent)
        .find(`.${jsHook}-pagination__current`)
        .text(sw.realIndex + 1);
}

function getSliderQuote(sw) {
    return sw.$el[0].closest('[data-module="sliderCardsB"]');
}

export default sliderCardsB;
