import './global/js/util/modernizr';
import './global/js/util/polyfills';
import './style.scss';
import './global/js/core/core';

//import cursorEffects from './global/js/animations/cursor-effects';
import parallaxScroll from './global/js/animations/parallax-light';
/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
import letterEffects from './global/js/animations/splittext';
/*
 * Misc
 */
import externalLinks from './global/js/misc/external-links/external-links';
// import scrollToAnchor from './global/js/misc/scroll/scroll-to-anchor';
/*
 * Atoms
 */
import floatingCta from './patterns/01-atoms/buttons/button-floating-cta-a/button-floating-cta-a-1/button-floating-cta-a-1';
import scrollToTop from './patterns/01-atoms/misc/scroll-to-top-a/scroll-to-top-a-1/scroll-to-top-a-1';
import { mobileNavigation } from './patterns/01-atoms/mobile-navigation/mobile-navigation-a-1/mobile-navigation-a-1';
/*
 * Molecules
 */
import showAccordionA from './patterns/02-molecules/accordions/accordion-a/accordion-a-1';
import showAccordionB from './patterns/02-molecules/accordions/accordion-b/accordion-b-1';
import showAccordionC from './patterns/02-molecules/accordions/accordion-c/accordion-c-1';
import debugControls from './patterns/02-molecules/admin/debug-controls-a/debug-controls-a-1';
import cardImageB from './patterns/02-molecules/cards/card-image-b/card-image-b-1';
import cardImageC from './patterns/02-molecules/cards/card-image-c/card-image-c-1';
import dropdown from './patterns/02-molecules/dropdowns/dropdown-a/dropdown-a-1';
import multiselectDropdown from './patterns/02-molecules/dropdowns/terms-multiselect-a/terms-multiselect-a-1';
import galleryA from './patterns/02-molecules/galleries/gallery-a/gallery-a-1';
import mapBlock from './patterns/02-molecules/maps/map-a/map-a-1';
import modal from './patterns/02-molecules/modals/modal-a/modal-a-1';
// import modalB from './patterns/02-molecules/modals/modal-b/modal-b-1';
import modalVideoA1 from './patterns/02-molecules/modals/modal-video-a/modal-video-a-1';
// import { hamburger } from './patterns/02-molecules/nav/hamburger-a/hamburger-a-1';
import navigateCategory from './patterns/02-molecules/nav/nav-category-a/nav-category-a-1';
import navMob from './patterns/02-molecules/nav/nav-mobile-a/nav-mobile-a-1';
import searchBar from './patterns/02-molecules/search/search-bar-a/search-bar-a-1';
import { slideOut } from './patterns/02-molecules/slide-outs/slide-out-a/slide-out-a-1';
import sliderImages from './patterns/02-molecules/sliders/slider-images-a/slider-images-a-1';
import sliderTestimonials from './patterns/02-molecules/sliders/slider-testimonials-a/slider-testimonials-a-1';
import socialShare from './patterns/02-molecules/social/social-share-a/social-share-a-1';
import tabs from './patterns/02-molecules/tabs/tabs-a/tabs-a-1';
import widgetTranslation from './patterns/02-molecules/widgets/widget-translation/widget-translation';
import sliderCardsB from './patterns/03-organisms/repeatable-custom/cards-b/cards-b-1/cards-b-1';
import sliderCardsC from './patterns/03-organisms/repeatable-custom/cards-c/cards-c-1/cards-c-1';
import sliderCardsE from './patterns/03-organisms/repeatable-custom/cards-e/cards-e-1/cards-e-1';
import filterType1 from './patterns/03-organisms/repeatable-custom/filter-a/filter-a-1/filter-a-1';
import sliderQuoteA from './patterns/03-organisms/repeatable-custom/quote-a/quote-a-1/quote-a-1';
import sliderVideosA from './patterns/03-organisms/repeatable-custom/videos-a/videos-a-1/videos-a-1';
import videosB1 from './patterns/03-organisms/repeatable-custom/videos-b/videos-b-1/videos-b-1';
/*
 * Organisms
 */
import header from './patterns/03-organisms/single/header/header-1/header-1';
import hero4 from './patterns/03-organisms/single/hero/hero-4/hero-4';

/*
 * Plop Entry (do not remove)
 */
/// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
/// /IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    showAccordionA,
    showAccordionB,
    showAccordionC,
    sliderImages,
    sliderTestimonials,
    header,
    navigateCategory,
    searchBar,
    scrollToTop,
    floatingCta,
    socialShare,
    // hamburger,
    hero4,
    slideOut,
    mobileNavigation,
    modal,
    // modalB,
    modalVideoA1,
    debugControls,
    tabs,
    mapBlock,
    dropdown,
    galleryA,
    filterType1,
    multiselectDropdown,
    sliderQuoteA,
    sliderCardsB,
    sliderCardsC,
    sliderCardsE,
    sliderVideosA,
    videosB1,
    cardImageB,
    cardImageC,
    letterEffects,

    /// /DO NOT ERASE OR PLOP IMPORTING WILL FAIL
    /// /MODULES FROM PLOP
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
const calledModulesNames = [];
modules.instantiate = function (elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw `Module '${module}' not found`;
    }
};

jQuery(document).ready(() => {
    // scrollToAnchor($);
    navMob($);
    widgetTranslation($);
    externalLinks($);

    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function () {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    letterEffects($);
    // scrollSkewer($);
});

jQuery(window).scroll(() => {
    parallaxScroll($);
});
