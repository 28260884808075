function VideosB() {
    const activeRepeaterItemClass = 'visible opacity-1';
    const disableRepeaterItemClass = 'invisible opacity-0';

    const activeButtonClass = 'o-videos-b-1__repeater-button--active';

    $('.o-videos-b-1__repeater-title-wrapper a').click(function (e) {
        e.preventDefault();

        const button = $(this);
        const parent = button.closest('[data-module="videosB1"]');
        const buttons = parent.find('.o-videos-b-1__btn');
        const repeater_items = parent.find('.o-videos-b-1__repeater-item');

        const hash = $(this).attr('href');
        const active_repeater_item = $(hash);

        buttons.removeClass(activeButtonClass);
        button.addClass(activeButtonClass);

        repeater_items.removeClass(activeRepeaterItemClass);
        repeater_items.addClass(disableRepeaterItemClass);

        active_repeater_item.removeClass(disableRepeaterItemClass);
        active_repeater_item.addClass(activeRepeaterItemClass);
    });
}

export default VideosB;
