function cardImageC1() {
    const jsHook = 'js-card-image-c';

    $(`.${jsHook}`).click(function () {
        if (window.innerWidth < 768) {
            console.log($(this).find(`.${jsHook}__social-overlay`));
            $(this).find(`.${jsHook}__social-overlay`).toggleClass('hovered');
        }
    });
}

export default cardImageC1;
