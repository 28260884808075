function cardImageB() {
    const jsHook = 'js-card-image-b';

    $(`.${jsHook}__btn`).click(function () {
        $(this)
            .parent()
            .attr('data-show', function (index, attr) {
                return attr === 'hidden' ? 'show' : 'hidden';
            });
    });
}

export default cardImageB;
