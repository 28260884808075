const selector = '.a-mobile-navigation';
const activeClass = 'a-mobile-navigation--active';
const bodyActiveClass = 'h-scroll-lock nav-active';
const headerActiveClass = 'o-header--hamburger-active';
const navMenu = '.js-slide-out';
const navMenuActiveClass = 'm-slide-out--active';
const headerSearchButton = 'o-header__search-button';
const searchBar = 'm-search-bar';

export function mobileNavigation() {
    $(selector).click((e) => {
        e.preventDefault();
        $(selector).toggleClass(activeClass);

        // Ensuring body cannot be scrolled up and down when the mobile nav is shown
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);

        // Makes slide-out appear
        $(navMenu).toggleClass(navMenuActiveClass);

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }
    });

    $(window).on('resize', () => {
        if ($(window).width() >= 1140) {
            if ($(selector).hasClass(activeClass)) {
                $(selector).trigger('click');
            }
        }
    });
}

export function mobileNavigationClose() {
    $(selector).removeClass(activeClass);
    $('.js-body').removeClass(bodyActiveClass);
    $('.js-header').removeClass(headerActiveClass);
    $(navMenu).removeClass(navMenuActiveClass);
}
