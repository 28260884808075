function showAccordionA() {
    $('.m-accordion-a__title-wrapper').click(function (e) {
        const target = e.target;
        if (target.closest('a')) {
            return;
        }

        e.preventDefault();

        $(this).parent().toggleClass('is-active');
        // eslint-disable-next-line jquery/no-slide
        $(this).parent().find('.m-accordion-a__content').slideToggle('fast');
    });

    // add listener to closing button
    // triggers click listeners of the '.m-accordion__title-wrapper'
    $('.m-accordion-a__close-btn').click(function () {
        $(this)
            .parents()
            .closest('.m-accordion-a__repeater-item')
            .find('.m-accordion-a__title-wrapper')
            .click();
    });
}

export default showAccordionA;
