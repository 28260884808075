/*
 * ScrollToTop
 *
 * runs the functionality of the scroll to top button that appears at the bottom right of the website
 */

function floatingCta() {
    const selector = '.js-floating-cta-button';
    const activeClass = 'a-floating-cta-button--active';
    const activeDistance = 200;
}

export default floatingCta;
