/* eslint-disable */

/*
 * Search Toggle
 *
 * runs the functionality of clicking a search icon and a search bar appears
 */

function searchBar() {
    // Search Toggle
    $('.js-header-search-toggle').on('click', function () {
        $(this).toggleClass('a-button-search--active');
        $('.m-search-bar').toggleClass('m-search-bar--active');
        $('body').toggleClass('h-scroll-lock');

        if ($('.algolia-autocomplete').css('display') == 'block') {
            $('.algolia-autocomplete').css('display', 'none');
        }

        if ($('.m-search-bar').hasClass('m-search-bar--active')) {
            searchBarActivated();
        } else {
            searchBarDeactivated();
        }
    });

    $('.js-header-search-toggle-mobile').on('click', function () {
        $('.js-slide-out-search-wrapper-mob').toggleClass(
            'c-slide-out__search-wrapper--active',
        );
        setTimeout(function () {
            $('.js-search-field-mob').focus();
        }, 600);
    });

    $('.m-search-bar__search-field').keyup(function (e) {
        if ($(this).val().length) {
            $('.m-popular-searches').css('display', 'none');
        } else {
            $('.m-popular-searches').css('display', 'block');
        }
    });

    /**
     * Hide Search Bar for small devices on resize Event
     **/
    window.addEventListener('resize', function () {
        const searchBar = document.querySelector('.m-search-bar');
        if (!searchBar || window.matchMedia('(min-width:1140px)').matches) {
            return false;
        }

        if (searchBar.classList.contains('m-search-bar--active')) {
            searchBar.classList.remove('m-search-bar--active');
            document.body.classList.remove('h-scroll-lock');

            const algoliaAutocomplete = document.querySelector(
                '.algolia-autocomplete',
            );
            if (algoliaAutocomplete) {
                algoliaAutocomplete.style.display = 'none';
            }
        }
    });

    function searchBarActivated() {
        setTimeout(function () {
            $('.m-search-bar__search-field').focus();
        }, 600);

        // stop Hero 4 autoplay
        $('.o-hero-4__slider-control').each(function () {
            let sliderControl = $(this);
            if (!sliderControl.hasClass('paused')) {
                sliderControl.click();
            }
        });
    }

    function searchBarDeactivated() {
        // activate Hero 4 autoplay
        $('.o-hero-4__slider-control').each(function () {
            let sliderControl = $(this);
            if (sliderControl.hasClass('paused')) {
                sliderControl.click();
            }
        });
    }
}

export default searchBar;
