function widgetTranslation() {
    // Language Widget Blocks

    // selectors to be used in the block
    const selector = {
        widget: '.js-widget-translation',
        toggler: '.js-widget-translation-toggler',
        headerBtn: '.js-widget-translation-header',
        content: '.m-widget-translation__content',
    };

    const widgets = document.querySelectorAll(selector.widget),
        headerBtns = document.querySelectorAll(selector.headerBtn);

    /**
     * main Click Event Listener
     **/
    document.addEventListener('click', function (e) {
        const el = e.target,
            button = el.closest(selector.toggler),
            parent = el.closest(selector.widget);

        // if not this block close Widgets and return
        if (!parent || !button) {
            widgets.forEach(function (item) {
                item.classList.remove('active');
            });
            return;
        }

        if (parent.classList.contains('active')) {
            parent.classList.remove('active');
        } else {
            const content = parent.querySelector(selector.content);

            parent.classList.add('active');
            content.classList.remove('hidden');
            content.classList.add('block');
        }
    });

    /**
     * transitionend Event for hiding Content - so the transition ended
     **/
    widgets.forEach(function (item) {
        item.addEventListener('transitionend', function (e) {
            if ('width' !== e.propertyName) {
                return;
            }

            const el = e.target,
                content = el.querySelector(selector.content);

            if (!el.classList.contains('active')) {
                content.classList.remove('block');
                content.classList.add('hidden');
            }
        });
    });

    /**
     * toggle Widget on special element click that is outside widget
     **/
    headerBtns.forEach(function (item) {
        item.addEventListener('click', function () {
            widgets[0].querySelector(selector.toggler).click();
        });
    });

    const languageSwitchersBlocks = document.querySelectorAll(
        `.js-google-translate-switcher`,
    );

    if (languageSwitchersBlocks.length) {
        // Looping through all of them (we can have several of them)
        [...languageSwitchersBlocks].map((languageSwitcherBlock) => {
            // Adding click listeners
            languageSwitcherBlock.addEventListener('click', (event) => {
                // Click Target
                const target = event.target;
                // Language switcher
                const button = target.closest('a');
                // If we click out of button - do nothing
                if (!button) {
                    return;
                }

                // Preventing default behaviour
                event.preventDefault();

                // Language value of this button
                const chosenLanguage = button.getAttribute('data-href');
                if (!chosenLanguage) {
                    return;
                }

                // Google Translate Select
                const googleTranslateSelect =
                    document.querySelector('.goog-te-combo');
                if (!googleTranslateSelect) {
                    return;
                }

                // Set the chosen value
                googleTranslateSelect.value = chosenLanguage;
                // Dispatching event and allow google to translate the page
                googleTranslateSelect.dispatchEvent(new Event('change'));
            });
        });
    }
}

export default widgetTranslation;
