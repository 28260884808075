import AOS from 'aos';

function showAccordionC() {
    const classes = {
        block: 'm-accordion-c',
        group: 'm-accordion-c__group',
        heading: 'm-accordion-c__heading-wrapper',
        link: 'm-accordion-c__heading-link',
        text: 'm-accordion-c__text-wrapper',
        track: 'm-accordion-c__track',
        active: 'is-active',
    };

    $(`.${classes.heading}`).click(function (e) {
        const group = $(e.target).closest(`.${classes.group}`);
        const text = group.find(`.${classes.text}`);
        const track = group.find(`.${classes.track}`);

        group.toggleClass(classes.active);
        track.toggleClass('rotate-180');
        // eslint-disable-next-line jquery/no-slide
        text.slideToggle(300);
        setTimeout(function () {
            AOS.refresh();
        }, 300);
    });

    /**
     * Disable scroll page to #hash on click
     * Remove Hash from URL when close Accordion
     **/
    $(`.${classes.link}`).click(function (e) {
        const group = $(e.target).closest(`.${classes.group}`);
        e.preventDefault();

        let newURL = '';
        if (group.hasClass(classes.active)) {
            const pageHashTarget = window.location.hash;
            newURL = window.location.href.replace(`${pageHashTarget}`, '');
        } else {
            newURL = e.currentTarget.href;
        }
        window.history.pushState({ path: newURL }, '', newURL);
    });

    /**
     * Open accordion if url-hash exists
     **/
    (function () {
        const anchor = location.hash
            ? document.querySelector(location.hash)
            : '';
        document.querySelectorAll(`.${classes.block}`).forEach(function (item) {
            if (anchor && anchor.closest(`.${classes.block}`) === item) {
                const group = anchor.closest(`.${classes.group}`);
                const link = group.querySelector(`.${classes.link}`);
                setTimeout(function () {
                    // scrollDown to active Accordion
                    window.scrollTo({
                        top: group.getBoundingClientRect().top + window.scrollY,
                        left: 0,
                        behavior: 'smooth',
                    });
                    // trigger click
                    link.click();
                }, 700);
            } else if (item.classList.contains('js-open-first')) {
                // Open Accordion first item
                // click on heading not link in order not to change url hash
                const heading = item.querySelector(
                    `.${classes.group}:nth-child(1) .${classes.heading}`,
                );
                setTimeout(function () {
                    heading.click();
                }, 700);
            }
        });
    })();
}

export default showAccordionC;
